<template>
  <auth-bg>
    <div class="container h-100">
      <div class="row h-100 align-items-center justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4 mx-auto">
          <div class="card py-4 px-3 rounded-5">
            <div class="card-header bg-transparent border-0 text-center h2 fw-bold text-uppercase">
              Forgot Password
            </div>
            <div class="card-body">
              <ValidationObserver v-slot="{ invalid }">
                <form class="auth-form" @submit.prevent="submit">
                  <ValidationProvider v-slot="v" rules="required|email" name="Email">
                    <text-input v-model="form.email" label="Email" placeholder="Email" type="email"
                                :error="hasError('email',v.errors)"
                                :errors="getErrors('email',v.errors)"
                    >
                      <template #append>
                        <span class="eye-btn"><i class="far fa-envelope"></i></span>
                      </template>
                    </text-input>
                  </ValidationProvider>
                  <button class="btn btn-primary d-block mx-auto w-75 rounded-pill text-uppercase mt-4" type="submit"
                          :disabled="invalid">Send
                  </button>
                  <a @click.prevent="login"
                     href="#"
                     class="text-center d-block mt-3 text-decoration-none text-uppercase text-muted">
                    Back</a>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </auth-bg>
</template>

<script>
import TextInput from "@/components/form/TextInput";
import hasApiValidation from "@/mixins/hasApiValidation";
import AuthBg from "@/components/AuthBg";

export default {
  name: "ForgotPassword",
  components: {AuthBg, TextInput},
  mixins: [hasApiValidation],
  data() {
    return {
      form: {
        email: '',
      },
      successRequest: false,
    }
  },
  methods: {
    openSuccessModal() {
      this.successRequest = true;
      setTimeout(() => {
        this.successRequest = false;
      }, 5000)
    },
    submit() {
      this.setErrors({});
      this.$http.post('/auth/forgot-password', this.form)
          .then(() => {
            this.$router.push({name: 'Login', query: {forgot_success: '1'}});
            this.setErrors({});
          })
          .catch(({response}) => {
            this.$noty.error(response.data.message);
            this.setErrors(response.data.errors);
          });
    },
    login() {
      this.$router.push({name: 'Login'});
    },

  }
}
</script>

<style scoped lang="scss">
</style>