<template>
  <div class="row">
    <div class="col-md-3 mb-3">
      <label class="d-block text-uppercase text-secondary">Start</label>
      {{ this.$options.filters.toHMTime(segment.start) }}
    </div>
    <div class="col-md-3 mb-3">
      <label class="d-block text-uppercase text-secondary">End</label>
      {{ this.$options.filters.toHMTime(segment.end) }}
    </div>
    <div class="col-md-3 mb-3">
      <label class="d-block text-uppercase text-secondary">Movement</label>
      {{ segment.movement_name }}
    </div>
    <div class="col-md-3 mb-3">
      <label class="d-block text-uppercase text-secondary">Cadence Range</label>
      {{ segment.cadence_range.min }} - {{ segment.cadence_range.max }}
    </div>
    <div class="col-md-3 mb-3">
      <label class="d-block text-uppercase text-secondary">Zone</label>
      {{ segment.zone }}
    </div>
    <div class="col-md-3 mb-3">
      <label class="d-block text-uppercase text-secondary">% Max Heart Rate</label>
      {{ segment.max_heart_rate.min }} - {{ segment.max_heart_rate.max !== 100 ? segment.max_heart_rate.max : 'Max' }}
    </div>
    <div class="col-md-3 mb-3">
      <label class="d-block text-uppercase text-secondary">FTP / PST %</label>
      {{ segment.pst.min !== -1 ? segment.pst.min : 'Below' }} -
      {{ segment.pst.max !== -1 ? segment.pst.max : 'Max' }}
    </div>
    <div class="col-md-3 mb-3">
      <label class="d-block text-uppercase text-secondary">RPE</label>
      {{ segment.rpe }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'AudioSegmentView',
  props: {
    segment: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {},
  watch: {}

}
</script>