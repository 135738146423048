<template>
  <div>
    <div class="d-flex align-items-center">
      <h1 class="fw-bold text-uppercase">Release Calendar</h1>
      <div class="d-flex align-items-center">
        <button class="btn" @click="prev"><i class="fa fa-chevron-left"></i></button>
        <span>{{ formatWeek(dates[0]) }} - {{ formatWeek(dates[1]) }} </span>
        <button class="btn" @click="next"><i class="fa fa-chevron-right"></i></button>
      </div>
    </div>
    <calendar style="height: 800px;"
              :schedules="attributes"
              :view="calendar_settings.view"
              :taskView="false"
              :scheduleView="['time']"
              :theme="calendar_settings.theme"
              :disableDblClick="true"
              :disableClick="true"
              :isReadOnly="false"
              :useCreationPopup="false"
              :useEditPopup="false"
              :useDetailPopup="true"
              :draggable="false"
              ref="calendar"
              :template="calendar_settings.template"
              @beforeCreateSchedule="onBeforeCreateSchedule"
              @beforeDeleteSchedule="onBeforeDeleteSchedule"
              @beforeUpdateSchedule="onBeforeUpdateSchedule"
    />
  </div>
</template>

<script>
import 'tui-calendar/dist/tui-calendar.css'
import {Calendar} from '@toast-ui/vue-calendar'
import theme from '../config/calendarTheme'

const getDate = (type, start, value, operator) => {
  start = new Date(start);
  type = type.charAt(0).toUpperCase() + type.slice(1);
  if (operator === '+') {
    start[`set${type}`](start[`get${type}`]() + value);
  } else {
    start[`set${type}`](start[`get${type}`]() - value);
  }
  return start;
};


export default {
  name: 'ReleaseCalendar',
  components: {
    'calendar': Calendar
  },
  data() {
    return {
      loading: false,
      calendar_settings: {
        scheduleList: [
          {
            id: '1',
            title: 'my schedule',
            category: 'time',
            dueDateClass: '',
            start: '2021-08-06T10:30:00+09:00',
            end: '2021-08-06T10:30:00+09:00'
          },
          {
            id: '2',
            title: 'second schedule',
            category: 'time',
            dueDateClass: '',
            start: '2021-08-07T10:30:00+09:00',
            end: '2021-08-07T10:30:00+09:00'
          }
        ],
        view: 'week',
        theme: theme,
        template: {
          popupDetailBody() {
            return 'test'
          },
          popupEdit(){
            return '<i class="fa fa-pencil"></i>'
          },
          popupDelete(){
            return ''
          },
        },
      },
      dates: {
        from: null,
        to: null,
      },
      data: [],
      url: '/sessions/release',
      masks: {
        weekdays: 'WWW',
      },
    };
  },
  mounted() {
    this.getDate();
  },
  methods: {
    onBeforeCreateSchedule(event) {
      var guide = event.guide;
      var guideEl$ = guide.guideElement ?
          guide.guideElement : guide.guideElements[Object.keys(guide.guideElements)[0]];
      let item = document.createElement('span');
      item.setAttribute("id", "add_span");
      item.innerHTML = 'Add Class <i class="fa fa-plus"></i>'
      guideEl$.appendChild(item)
      item.addEventListener('click', this.add_click)
    },
    add_click(event){
      console.log(event)
    },
    onBeforeDeleteSchedule(e) {
      console.log('onBeforeDeleteSchedule', e)
    },
    onBeforeUpdateSchedule(e) {
      console.log('onBeforeUpdateSchedule', e)
    },
    fetchData() {
      this.loading = true;
      this.$http.get(this.url, {
        params: {
          dates: {
            from: this.$moment(this.dates[0]).format('YYYY-MM-DD'),
            to: this.$moment(this.dates[1]).format('YYYY-MM-DD'),
          }
        }
      }).then(({data}) => {
        this.data = data.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    getDate() {
      let data_start = this.$refs.calendar.invoke('getDateRangeStart');
      let data_end = this.$refs.calendar.invoke('getDateRangeEnd');
      this.dates = [data_start.toDate(), data_end.toDate()]
      this.fetchData();
    },
    next() {
      this.$refs.calendar.invoke('next');
      this.getDate();
    },
    prev() {
      this.$refs.calendar.invoke('prev');
      this.getDate();
    },
    today() {
      this.$refs.calendar.invoke('today');
    },
    formatWeek(date) {
      return this.$moment(date).format('YYYY-MM-DD')
    }
  },
  computed: {
    attributes() {
      return [
        // Attributes for todos
        ...this.data.map(item => (
            {
              id: item.id,
              title: item.name,
              category: 'time',
              dueDateClass: '',
              start: item.release_date,
              end: getDate('hours', item.release_date, 1, '+').toISOString(),
              body: true
            })),
      ];
    },
  },
}
</script>
