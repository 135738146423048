<template>
  <div class="">
    <div class="d-flex justify-content-between">
      <h1 class="fw-bold text-uppercase">Not Found</h1>
    </div>
  </div>
</template>
<script>


export default {
  name: "NotFound",
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
  }
}
</script>
<style scoped>
</style>