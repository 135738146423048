<template>
  <div class="form-group " :class="{'has-validation': error, 'mb-3' : !inline}" :id="group">
    <slot name="label"></slot>
    <slot name="input"></slot>
    <slot></slot>
    <slot name="error">
      <div class="invalid-feedback d-block" v-if="errors.length > 0">
        <span class="d-block" v-for="(err, key) in errors" :key="'err-'+key">{{ err }}</span>
      </div>
    </slot>
  </div>
</template>

<script>
import {uuid} from 'vue-uuid';
import {Popover} from "bootstrap";

export default {
  name: "FormGroup",
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    inline: {
      type: Boolean,
      default: false
    },
    popoverContent: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      group: null
    }
  },
  mounted() {
    this.group = uuid.v4();
    if (this.popoverContent) {
      let elem = this.$el
      new Popover(elem, {
        content: this.popoverContent,
        placement: 'top',
        trigger:'hover focus'
      })
    }
  }
}
</script>

<style scoped>

</style>