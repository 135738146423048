<template>
  <form class="annotation_form">
    <div class="row">
      <div class="col-md-2">
        <text-input v-model="new_annotation.name" label="Name" class="flex-grow-1"
                    :error="hasError('name')"
                    :errors="getErrors('name')"
                    disabled
        ></text-input>
      </div>
      <div class="col-md-2">
        <form-group :error="hasError('instant')" :errors="getErrors('instant')">
          <template #label><label class="fw-medium">Time</label></template>
          <the-mask v-model="start_segment"
                    placeholder="00:00"
                    class="form-control"
                    :masked="true"
                    mask="##:##"
          ></the-mask>
        </form-group>
      </div>
      <div class="col-md-4">
        <text-input v-model="new_annotation.description" label="Description"
                    :error="hasError('description')"
                    :errors="getErrors('description')"
        ></text-input>
      </div>
      <div class="col-auto col-sm-3 col-lg-2 ms-auto d-flex align-items-center justify-content-end">
        <span class="me-3 annotation_icons" @click="saveAnnotation()"><i class="fa fa-check "></i></span>
        <span class="annotation_icons" @click="deleteAnnotation()"><i class="fa fa-trash "></i></span>
      </div>
    </div>
  </form>
</template>
<script>
import TextInput from "@/components/form/TextInput";
import hasApiValidation from "@/mixins/hasApiValidation";
import FormGroup from "@/components/form/FormGroup";
import {mask} from 'vue-the-mask'
import {TheMask} from 'vue-the-mask'

export default {
  name: 'AudioAnnotationForm',
  components: {FormGroup, TextInput, TheMask},
  mixins: [hasApiValidation],
  directives: {mask},
  props: {
    id: {
      required: true
    },
    annotation: {
      type: Object,
    },
    max: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      new_annotation: {
        instant: null,
        id: 'new_annotation',
        name: null,
        description: null,
        errors: {},
      }
    }
  },
  mounted() {
    if (this.annotation) {
      this.new_annotation = Object.assign({}, this.annotation);
    }
  },
  methods: {
    deleteAnnotation() {
      if (!this.annotation) {
        this.new_annotation = {
          instant: null,
          id: 'new_annotation',
          name: null,
          description: null,
          errors: {},
        }
      }
      this.$emit('delete', this.new_annotation);
    },
    updateValue(start, time) {
      this.new_annotation[time] = start;
    },
    updateName(name) {
      this.new_annotation.name = name;
    },
    saveAnnotation() {
      if (this.annotation) {
        this.$emit('errors', {errors: {}, annotation: this.annotation});
      } else {
        this.setErrors({});
      }

      this.$http.put(`/sessions/${this.id}/annotations/validate`, this.new_annotation).then(() => {
        this.new_annotation.errors = null;
        if (!this.annotation) {
          this.$emit('saved', this.new_annotation);
          this.new_annotation = {
            instant: null,
            id: 'new_annotation',
            name: null,
            description: null,
            errors: {},
          }
        } else {
          this.$emit('updated', this.new_annotation);
          this.new_annotation = {
            instant: null,
            id: 'new_annotation',
            name: null,
            description: null,
            errors: {},
          }
        }
      }).catch(({response}) => {
        this.new_annotation.errors = {};
        if (this.annotation) {
          this.$emit('errors', {errors: response.data.errors, annotation: this.annotation});
        } else {
          this.setErrors(response.data.errors);
        }
      })

      // if (this.new_annotation.id) {
      //   this.$http.put(`/sessions/${this.id}/annotations/${this.new_annotation.id}`, this.new_annotation).then(({data}) => {
      //     this.setErrors({});
      //     this.$emit('updated', data.data);
      //   }).catch(({response}) => {
      //     this.setErrors(response.data.errors);
      //   })
      // } else {
      //   this.$http.post(`/sessions/${this.id}/annotations`, this.new_annotation).then(({data}) => {
      //     this.setErrors({});
      //     this.$emit('saved', data.data);
      //   }).catch(({response}) => {
      //     this.setErrors(response.data.errors);
      //   })
      // }
    },
    parseValue(event) {
      let a = event.split(':'); // split it at the colons
      let seconds = (+parseInt(a[0])) * 60 + (+parseInt(a[1]));
      if (seconds > this.max) {
        this.new_annotation.instant = Math.floor(this.max);
      } else {
        this.new_annotation.instant = seconds;
      }
    },
    hasError(key) {
      return (this.annotation && this.annotation.errors && !!this.annotation.errors[key]) || (this.errors && !!this.errors[key]);
    },
    getErrors(key) {
      return (this.annotation && this.annotation.errors && this.annotation.errors[key]) ?
        (typeof this.annotation.errors[key] === 'string') ? [this.annotation.errors[key]] : this.annotation.errors[key]
        : (this.errors && this.errors[key]) ?
          (typeof this.errors[key] === 'string') ? [this.errors[key]] : this.errors[key]
          : [];
    }
  },
  computed: {
    start_segment: {
      get: function () {
        if (this.new_annotation.instant) {
          let minutes = Math.floor((this.new_annotation.instant / 60));
          let seconds = this.new_annotation.instant - (minutes * 60);

          if (minutes < 10) {
            minutes = "0" + minutes;
          }
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          return minutes + ':' + seconds;
        } else {
          return '00:00'
        }
      },
      set: function (val) {
        this.parseValue(val)
      },
    },
  },
  watch: {
    'new_annotation.instant': {
      handler: function (val) {
        if (val > this.max) {
          this.new_annotation.instant = Math.floor(this.max);
        }
      }
    },
  }

}
</script>
<style scoped>
.annotation_icons {
  width: 26px;
  height: 26px;
  background-color: var(--bs-primary);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

</style>