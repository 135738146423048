<template>
  <div class="row">
    <div class="col-md-2 mb-3">
      <label class="d-block text-uppercase text-secondary">Time</label>
      {{annotation.instant | toHMTime}}
    </div>
    <div class="col-md-4 mb-3">
      <label class="d-block text-uppercase text-secondary">Description</label>
      {{annotation.description}}
    </div>
  </div>
</template>
<script>

export default {
  name: 'AudioAnnotationView',
  components: {},
  props: {
    annotation: {
      type: Object,
    },
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }

}
</script>
<style scoped>

</style>