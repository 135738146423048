<template>
  <div class="modal fade" tabindex="-1" id="seg_errors_item_modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content rounded-xl p-3">
        <div class="modal-header d-flex justify-content-end border-0 p-0">
          <button type="button" class="btn-close opacity-0" @click="cancel" aria-label="Close"><i class="fa fa-times"></i>
          </button>
        </div>
        <div class="modal-content text-center border-0 px-4 pb-4">
          <h3 class="modal-title fw-bold text-uppercase mb-3">{{ title }}</h3>
          <button type="button" class="btn btn-primary w-50 mx-auto rounded-pill py-2 text-uppercase fw-bold"
                  @click="confirm()">Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Modal} from 'bootstrap'

export default {
  name: "SegmentErrorsModal",
  props: {
    title: {
      type: String,
      default: 'Please correct the Segment Start Time'
    },
  },
  data() {
    return {
      m_object: null,
      data: null,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let obj = document.getElementById('seg_errors_item_modal');
      this.m_object = new Modal(obj, {})
      obj.addEventListener('hidden.bs.modal', this.cancel)
    },
    open(data) {
      this.data = data;
      this.m_object.show();
    },
    confirm() {
      this.$emit('errs_confirm')
      this.data = null;
      this.m_object.hide();
    },
    cancel() {
      this.$emit('cancel')
      this.data = null;
      this.m_object.hide();
    }
  }
}
</script>
<style scoped>
.rounded-xl {
  border-radius: 30px;
}

button.btn-close {
  background: none;
  font-size: 25px;
  padding: 0 !important;
  margin: -10px 0 0 0 !important;
  width: auto;
  height: auto;
}


</style>