<template>
  <ValidationObserver v-slot="{ handleSubmit , reset }">
    <form @submit.prevent="handleSubmit(submit)" ref="form_profile" @reset.prevent="reset" >
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between align-items-center">
          <h3>Change Email <span class="text-secondary fw-normal fs-5" v-if="$auth.user().new_email">(We already waiting for confirmation {{
              $auth.user().new_email
            }})</span>
          </h3>
          <button class="btn btn-primary rounded-pill py-2 text-uppercase" type="submit">Submit</button>
        </div>
        <div class="col-md-6">
          <ValidationProvider v-slot="v" rules="required|email" name="Email">
            <text-input v-model="form.email" label="Email" placeholder="Email" required type="email"
                        :error="hasError('email', v.errors)" :errors="getErrors('email', v.errors)"></text-input>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider v-slot="v"
                              :rules="{required: true,email:true, confirm: form.email,}" name="Email Confirmation">
            <text-input v-model="form.email_confirmation" label="Confirm Email" placeholder="Email" required
                        type="email"
                        :error="hasError('email_confirmation', v.errors)"
                        :errors="getErrors('email_confirmation', v.errors)"></text-input>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import TextInput from "@/components/form/TextInput";

export default {
  name: "EmailChange",
  components: {TextInput},
  mixins: [hasApiValidation],
  props: [
    'email_token',
  ],
  data: () => {
    return {
      form: {
        email: '',
        email_confirmation: '',
      },
      loading: false,
    }
  },
  mounted() {
    if (this.$auth.user().new_email && this.email_token) {
      this.loading = true;
      this.checkToken()
    }
  },
  methods: {
    submit() {
      this.setErrors({})
      this.$http.put('/profile/email', this.form).then(({data}) => {
        this.$noty.success(data.message);
        this.$emit('change');
        this.form = {
          email: '',
          email_confirmation: '',
        };
        this.$refs.form_profile.reset();
      }).catch(({response}) => {
        this.setErrors(response.data.errors)
      })
    },
    checkToken() {
      this.$http.put('/profile/email/confirm', {
        email: this.$auth.user().new_email,
        token: this.email_token
      }).then(({data}) => {
        this.$noty.success(data.message);
        this.$emit('change');
        this.loading = false;
      }).catch(({response}) => {
        this.setErrors(response.data.errors)
        this.loading = false;
      })
    }
  }

}
</script>

<style scoped>

</style>