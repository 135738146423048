<template>
  <form-group v-bind="{error, errors, inline}">
    <template #label>
      <slot name="label">
        <label v-if="label" :for="safeId" class="fw-medium d-flex ">
          <span>
          {{label}}
          </span>
          <span v-if="required" class="px-2">
            *
          </span>
        </label>
      </slot>
    </template>
    <template #input>
      <div class="input-group">
        <input
            v-bind="$attrs"
            :id="safeId"
            :type="type"
            v-bind:value="value"
            v-on:input="$emit('input', $event.target.value)"
            @input="onInput($event)"
            @change="onChange($event)"
            class="form-control"
            :class="{'is-invalid':error, 'form-control-sm' : size == 'sm', 'shadow': shadow}"
            v-on="listeners"
        >
        <slot name="append"></slot>
      </div>
    </template>
  </form-group>
</template>

<script>
import FormGroup from "@/components/form/FormGroup";
export default {
  name: "TextInput",
  components: {FormGroup},
  inheritAttrs: false,
  props: {
    value: {default: ''},
    type: {default: 'text'},
    label: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    shadow: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => [],
    },

  },
  methods: {
    onInput (e) {
      this.state = e.target.value
      this.$emit('input', this.state, e)
      if (this.lazy === true) {
        return
      }

      clearTimeout(this.syncTimeout)
      this.syncTimeout = setTimeout(() => {
        this.$emit('update:value', this.state, e)
      }, this.lazy !== false ? this.lazy : 0)
    },
    onChange (e) {
      this.state = e.target.value
      this.$emit('change', this.state, e)
      this.$emit('update:value', this.state, e)
    },
  },
  computed:{
    listeners () {
      const { input, change, ...listeners } = this.$listeners // eslint-disable-line no-unused-vars
      return listeners
    },
    safeId: function safeId() {
      if (this.id || this.$attrs.id) {
        return this.id || this.$attrs.id;
      }
      let key = function key() {
        return Math.random().toString(36).replace('0.', '');
      };

      return key();
    }
  }
}
</script>

<style scoped>
.disabled_normal input{
  background-color: white!important;
  text-align: center;
}
</style>