<template>
  <div class="table-container">
    <div class="table-responsive">
      <table class="table mb-0">
        <thead>
        <tr class="font-weight-bold">
          <th v-for="(column, index) in columns" :key="'th-'+column.key"
              @click="changeSort(column, index)"
              :class="[headerClass(index), {'position-relative pr-4' : true }]"
              :style="headerStyles(index)"
          ><span>{{ column.label ? column.label : column.key }} </span> <span v-html="getSortIcon(column.key)"></span>
          </th>
        </tr>
        </thead>
        <tbody v-if="loading">
        <tr>
          <td colspan="8">
            <div class="d-flex w-100 justify-content-center py-2">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
        <tbody v-else-if="data.length === 0">
        <tr>
          <td colspan="8">
            No classes
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr v-for="item in data" :key="item.id">
          <td v-for="column in columns" :key="'row-'+item.id+'-td-'+column.key" :class="column._classes"
              style="vertical-align: middle">
            <template v-if="!column.slot">
          <span v-if="!column.format">
          {{ item[column.key] }}
          </span>
              <span v-else>{{ dynamicFilter(column.format, item[column.key]) }}</span>
            </template>
            <template v-else>
              <slot
                  :name="column.key"
                  :item="item"
                  :index="item.id"
              />
            </template>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex align-items-baseline justify-content-end">
      <pagination :current-page="options.page" @paginate="pageChange" :last-page="options.last_page" class="mr-3"
                  :loading="loading"/>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  name: 'MainTable',
  components: {Pagination},
  props: {
    columns: {
      required: true,
      type: Array,
    },
    data: {
      required: true,
      type: Array,
    },
    options: {
      required: true,
      type: Object,
    },
    loading: {
      required: false,
      default: false,
    }

  },
  data() {
    return {
      sorterState: '',
      per_page_options: [
        {value: 1, label: '1 / page'},
        {value: 5, label: '5 / page'},
        {value: 10, label: '10 / page'},
        {value: 15, label: '15 / page'},
        {value: 25, label: '25 / page'},
        {value: 50, label: '50 / page'},
      ]
    }
  },
  methods: {
    isSortable(index) {
      return (!this.columns || this.columns[index].sort !== false)
    },
    headerClass(index) {
      const fields = this.columns
      return fields && fields[index]._header_classes ? fields[index]._header_classes : ''
    },
    headerStyles(index) {
      let style = ''
      if (this.isSortable(index)) {
        style += `cursor:pointer;`
      }
      if (this.columns && this.columns[index] && this.columns[index]._style) {
        style += this.columns[index]._style
      }
      return style
    },
    changeSort(column, index) {
      let sort_field = column.sort ? column.sort : column.key
      if (!this.isSortable(index)) {
        return
      }
      let order = '';
      if (this.sortData.order_by === sort_field) {
        order = '-';
        if (this.sortData.order === 'desc') {
          this.sorterState = ''
          this.$emit('update:sorter-value', {order: this.sortData.order, order_by: this.sortData.order_by})
          return;
        }
      }
      this.sorterState = order + sort_field;
      this.$emit('update:sorter-value', {order: this.sortData.order, order_by: this.sortData.order_by})
    },
    getSortIcon(field) {
      let suffix = '';
      if (this.sortData.order_by === field) {
        suffix = this.sortData.order === 'asc' ? '-up' : '-down';
      }
      return suffix ? '<i class="fas fa-chevron' + suffix + '"></i>' : '';
    },
    pageChange(page) {
      this.$emit('paginate', page);
    },
    perPageChange(per_page) {
      this.$emit('per_page_change', per_page);
    },
    dynamicFilter(filterName, value) {
      return this.$options.filters[filterName](value);
    }
  },
  computed: {
    sortData() {
      let data = {
        order: null,
        order_by: null,
      };
      if (this.sorterState) {
        data.order = this.sorterState.startsWith('-') ? 'desc' : 'asc';
        data.order_by = this.sorterState.startsWith('-') ? this.sorterState.slice(1) : this.sorterState;
      }
      return data;
    }
  },

}
</script>
<style>
</style>